.datosI {
    margin: 4rem 6rem;
    text-align: center;

}

.datosI h2{
    position: relative;
    font-size: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    color: black;
    
}
.datosI h1{
    position: relative;
    font-size: 3rem;
    margin-right: 1rem;
    margin-top: 2rem;
    color: #004AAD;
    
}

.first-des h1{
    position: relative;
    font-size: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    color: black;
    
}

.first-desIn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    margin-bottom: 2rem;
}

.first-desI{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.first-desI h1{
    position: relative;
    font-size: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    color: black;
    
}

.first-desIn .imageI{
    width: 50rem;
    height: 30rem;
    display: flex;
    align-items: center;
    margin-right: 2.5rem;
    margin-left: 3rem;
    margin-bottom: 3rem;
    justify-content: space-between;
    z-index: -99;  
    flex-direction: column;
}

.imageI img{
    width: 90%;
    height: 90%;
    object-fit: cover;
    margin-right: 2.5rem;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
}

.first-desIn .imageI p{
    font-size: 1.4rem;
    
}

.first-desIn .slider{
        
    margin-bottom: 4rem;
    
}

.first-desIn .videoI{
    width: 100%;
   margin-left: 10%;
    margin-bottom: 2rem;
    
}

.first-desIn .Vslider{
    
    margin-left: -30rem;
    
    
}

.first-desIn h2{
    position: relative;
    width: 70%;
    
    
}




@media screen and (max-width: 1250px) {
    
    .imageI{
        width: 40rem;
        height: 500px;
        margin: 1.8rem 0;
    }
    .imageI img{
        height: auto;
        margin-top: 2rem;
        margin-left: 5rem;
    }

    .first-desI{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4.5rem;
    }

    .first-desIn{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4.5rem;
    }

    .first-desIn .imageI{
        width: 50rem;
        height: 40rem;
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        margin-left: 3rem;
        margin-bottom: 3rem;
        justify-content: space-between;
        z-index: -99;  
        flex-direction: column;
    }

    .imageI img{
        width: 90%;
        height: 80%;
        object-fit: cover;
        margin-right: 2.5rem;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }

    .datosI h2{
        font-size: 1.5rem;
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
        
        
    }

    .first-desIn .slider{
        
        margin-bottom: 4rem;
        margin-left: 0rem;
        margin-right: 0rem;
        
    }
    .first-desIn .videoI{
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-bottom: 2rem;
        
    }

    .first-desIn .Vslider{
    
        margin-left: 0rem;
        
        
    }


    
    
}

@media screen and (max-width: 800px){

    .first-desIn .imageI{
        width: 25rem;
        height: 20rem;
        
        display: flex;
        align-items: center;
        margin-right: 0rem;
        margin-left: -2rem;
        margin-bottom: 3rem;
        justify-content: space-between;
        z-index: -99;  
        flex-direction: column;
    }

    .first-desIn .slider{
        margin-left: 0rem;
        margin-right: 0rem;
        
    }

    .first-desIn .videoI{
        width: 35rem;
        height: 30rem;
        margin-bottom: 2rem;
        
    }

    .datosI h2{
        font-size: 1.5rem;
        width: 100%;
        margin-right: 0;
        
        
    }
    .datosI h1{
        font-size: 2rem;
        width: 100%;
        margin-right: 0;
    }

    


}

@media screen and (max-width: 600px){

    .first-desIn .imageI{
        width: 25rem;
        height: 15rem;
        display: flex;
        align-items: center;
        margin-right: 2rem;
        margin-left: 2rem;
        margin-bottom: 3rem;
        justify-content: space-between;
        z-index: -99;  
        flex-direction: column;
    }

    .first-desIn h2{
        
        margin-top: 4.5rem;
        margin-bottom: 4rem;
    }

    .first-desIn .videoI{
        width: 30rem;
        height: 30rem;
        margin-bottom: 2rem;
        
    }



}

@media screen and (max-width: 500px){

    .first-desIn .imageI{
        width: 18rem;
        height: 15rem;
        display: flex;
        align-items: center;
        margin-right: 2rem;
        margin-left: 2rem;
        margin-bottom: 3rem;
        justify-content: space-between;
        z-index: -99;  
        flex-direction: column;
    }

    .first-desIn .videoI{
        width: 30rem;
        margin-left: -5rem;
        margin-bottom: 2rem;
        
    }

    .datosI h2{
        font-size: 1rem;
        width: 100%;
        
        
    }
    .datosI h1{
        font-size: 1.5rem;
        width: 100%;
    }


}

@media screen and (max-width: 400px){

    .first-desIn .imageI{
        width: 15rem;
        height: 15rem;
        display: flex;
        align-items: center;
        margin-right: 2rem;
        margin-left: -2rem;
        margin-bottom: 3rem;
        justify-content: space-between;
        z-index: -99;  
        flex-direction: column;
    }

    .first-desIn .videoI{
        width: 20rem;
        margin-left: -4rem;
        margin-bottom: 2rem;
        
    }

    .datosI h2{
        font-size: 1rem;
        width: 100%;
        
        
        
    }
    .datosI h1{
        font-size: 1.5rem;
        width: 100%;
    }

    .first-desI h1{
        width: 150%;
        font-size: 1.2rem;
        
    }

    .first-desIn .slider{
        width: 15rem;
        height: 15rem;
        margin-left: 0rem;
        margin-right: 0rem;
        
    }

    


}