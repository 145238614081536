.slider{
    width:500px;
    height: 280px;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .slider{
        width: 400px;
        height: 200px;
        margin: 0 auto;
    }

}

@media screen and (max-width: 400px) {
    .slider{
        width: 280px;
        height: 140px;
        margin: 0 auto;
    }

}