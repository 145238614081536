@import "~@openfonts/cardo_all/index.css";

nav {
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}
#site-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
#site-title li {
  list-style: none;
  padding: 0px 0px;
  position: relative;
}

#site-title li a {
  text-decoration: none;
  font-size: 2.3rem;
  font-weight: 600;
  color: #000000;
  transition: 0.3s ease-in-out;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: #000000;
  transition: 0.3s ease-in-out;
}
#navbar li a:hover,
#navbar li a.active {
  color: #004AAD;
}

#navbar li a:hover::after,
#navbar li a.active::after {
  content: "";
  width: 50%;
  height: 2px;
  background: #004AAD;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#mobile {
  display: none;
}

#mobile i {
  color: #000000;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    z-index: 1;
    top: 80px;
    right: -100%;
    width: 60%;
    height: 100vh;
    background: rgba(243, 243, 243,0.8);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    z-index: 1;
    top: 4.5rem;
    right: -100%;
    width: 80%;
    height: 100vh;
    background: rgba(243, 243, 243,0.8);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #site-title {
    position: relative;
    margin-left: -15%; 
    
  }

  #site-title h2{
    font-size: 70%;
    
  }


  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}

@media screen and (max-width: 400px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    z-index: 1;
    top: 80px;
    right: -100%;
    width: 90%;
    height: 100vh;
    background: rgba(243, 243, 243,0.8);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #site-title {
    position: relative;
    margin-left: -30%;
    margin-right: 10%;
    
  }

  #site-title h2{
    font-size: 70%;
    
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}


@media screen and (max-width: 300px) {


  #site-title {
    position: relative;
    margin-left: -40%;
    margin-right: 10%;
    
 
  }
}