.video{
    
    padding-left: 0%;
    padding-right: 10%;
    padding-top: 5%;
    position: flex;
    margin-bottom: 2rem;
    width: 60%;
}

@media screen and (max-width:1600px) {
    .video{
    
        padding-left: 0%;
        padding-right: 5%;
        padding-top: 5%;
        position: flex;
        margin-bottom: 2rem;
        width: 60%;
    }
}

@media screen and (max-width:1500px) {
    .video{
    
        padding-left: 0%;
        padding-right: 5%;
        padding-top: 5%;
        position: flex;
        margin-bottom: 2rem;
        width: 50%;
    }
}

@media screen and (max-width:1250px) {
    .video{
    
        padding-left: 0%;
        padding-right: 5%;
        padding-top: 5%;
        position: flex;
        margin-bottom: 2rem;
        width: 100%;
    }
}

@media screen and (max-width:700px) {
    .video{
    
        padding-left: 20%;
        padding-right: 10%;
        padding-top: 5%;
        position: flex;
        margin-bottom: 5rem;
        width: 100%;
        

    }
}


@media screen and (max-width:500px) {
    .video{
    
        padding-left: 25%;
        padding-right: 10%;
        padding-top: 5%;
        position: flex;
        
        width: 100%;
        

    }
}

@media screen and (max-width:400px) {
    .video{
    
        padding-left: 30%;
        padding-right: 10%;
        padding-top: 5%;
        position: flex;
        
        width: 100%;
        

    }
}

