.datosO {
    margin: 4rem 6rem;
    text-align: center;

}

#opinion{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.datosO h1{
    position: relative;
    font-size: 3rem;
    color: #004AAD;
    
}

.des-textO h2{
    position: relative;
    
    color: #004AAD;
}

.first-des-ra{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5.5rem;
}
.first-des-r{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5.5rem;
}


.des-textO{
    padding-top: 5rem;
    width: 75%;
    text-align: start;
    font-size: 1.5rem;
    margin-right: 2rem;
    
}

.des-textO h2{
    padding-bottom: 1rem;
}

.image{
    position: relative;
    width: 75%;
    display: cover;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    justify-content: space-between;
    z-index: -99;
}

.image img{
    width: 75%;
    height: 75%;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
}

.btn{
    background-color: transparent;
    border: none;
    font-weight: 500;
    color: #004AAD;
    cursor: pointer;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

@media screen and (max-width: 1000px) {
    .datosO {
        margin: 4rem 2rem;
    
    }

    .first-des-r{
        display: cover;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }

    .first-des-ra{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }
    

    .des-textO{
        width: 90%;
        
    }
    .image{
        width: 100%;
        margin: 1.8rem 0;
    }
    .image img{
        height: auto;
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 600px) {
    .datosO {
        margin: 4rem 0.5rem;
    
    }

    .first-des-r{
        display: cover;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }

    .first-des-ra{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    

    .des-textO{
        width: 75%;
        
    }

    .des-textO .parrafo{
        position: relative;
        font-size: 1.4rem;
        
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }
    .image img{
        height: 20rem;
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 500px) {
    .datosO {
        margin: 4rem 0.5rem;
    
    }

    .first-des-r{
        display: cover;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }

    .first-des-ra{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    

    .des-textO{
        width: 70%;
        
    }

    .des-textO .parrafo{
        position: relative;
        font-size: 1.4rem;
        
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }
    .image img{
        height: 20rem;
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 400px) {
    .datosO {
        margin: 4rem 0rem;
    
    }

    .first-des-r{
        display: cover;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }

    .first-des-ra{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    

    .des-textO{
        width: 70%;
        
    }

    .des-textO h2{
        position: relative;
        font-size: 1.5rem;
        color: #004AAD;
        margin-left: 5%;
        margin-right: 5%;
        
    }
    

    .des-textO .parrafo{
        position: relative;
        font-size: 1rem;
        
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }
    .image img{
        height: 20rem;
        width: 8rem;
        margin-top: 2rem;
        margin-left: -2rem;
    }
    
}

@media screen and (max-width: 300px) {
    .datosO {
        margin: 4rem 0.5rem;
    
    }

    .first-des-r{
        display: cover;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }

    .first-des-ra{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    

    .des-textO{
        width: 70%;
        
    }

    .des-textO h2{
        position: relative;
        font-size: 1.5rem;
        color: #004AAD;
    }
    

    .des-textO .parrafo{
        position: relative;
        font-size: 1rem;
        
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }
    .image img{
        height: 20rem;
        width: 8rem;
        margin-top: 2rem;
        margin-left: -2rem;
    }
    
}


