.Eventos{
    font-family: 'Poppins', sans-serif;
}

.tituloE h1{
    position: relative;
    font-size: 3rem;
    color: #004AAD;

    margin-top: 5rem;
    margin-bottom: 5rem;

}

@media screen and (max-width: 600px){
    .tituloE h1{
        position: relative;
        font-size: 2rem;
        color: #004AAD;
    
        margin-top: 5rem;
        margin-bottom: 5rem;
    
    }
}