.footer{
    padding: 2rem 8rem ;
    background: rgb(209, 209, 209);
}

.first-desF{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.first-desF h1{
    position: relative;
    font-size: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    color: black;
    
}

@media screen and (max-width: 1000px) {
    
    .first-desF {
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }

}