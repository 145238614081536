.datos {
    margin: 4rem 6rem;
    text-align: center;
}

.datos h1{
    font-size: 3rem;
    color: #004AAD;
}

.Datos{
    margin-top: 3rem;
    display: flex;
    
    justify-content: space-between;
}

.t-card{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.19);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.t-card h4{
    font-size: 3rem;
    color: #004AAD;
}

.t-card p{
    text-align: center;
    
}

.t_image{
    height: 500px;
    overflow: hidden;
    border-radius: 7px;
}
.t_image img{
    width: 100%;
    height: 100%;
}
.t-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
}

@media screen and (max-width:1000px) {
    .datos{
        margin: 4rem 2rem;
    }
    .Datos{
        
        flex-direction: column;
    }
    .t-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }
}


@media screen and (max-width:400px) {
    .datos h1{
        font-size: 1.5rem;
    }
}
