.datosE {
    margin: 4rem 6rem;
    text-align: center;
}

.datosE h1{
    font-size: 3rem;
    color: #004AAD;
    
}

.DatosE{
    margin-top: 3rem;
    display: flex;
    
    justify-content: space-between;
}

.t-card{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.19);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.t-card h4{
    font-size: 3rem;
    color: #004AAD;
}

.t_image{
    height: 500px;
    overflow: hidden;
    border-radius: 7px;
}
.t_image img{
    width: 100%;
    height: 100%;
}
.t-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
    text-align: center;
}

.first-des-reverse{
    display: flex;
    height:  40rem;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    
}

.des-textE{
    width: 60%;
    text-align: start;
    font-size: 2rem;
    margin-right: 2rem;
    margin-left: 5rem;
    margin-bottom: 4rem;
    
}

.des-textE h1{

    margin-bottom: 2rem;
    
}

.sliderE{
    position: relative;
    
    
    margin-bottom: 5rem;
}



@media screen and (max-width:1500px) {
    .datosE{
        margin: 4rem 2rem;
    }
    
    .t-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }


    .slider{
        position: relative;
        
        width: 15rem;
        height: 15rem;
    }

    .first-des-reverse{
        
        margin-top: 6.5rem;
        margin-bottom: 10rem;
        
    }
    .des-textE{
        width: 75%;
        
    }
    .des-textE p{
        width: 80%;
        font-size: 1.8rem;
    }
    
}


@media screen and (max-width:1000px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }
    .t-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        margin-bottom: 20rem;
        
    }
    .first-des-reverse .slider{
        position: relative;
        
        margin-bottom: 4rem;
    }
    
}


@media screen and (max-width:800px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }
    .first-des-reverse{
        
        margin-top: 2.5rem;
        margin-bottom: 35rem;
        
    }
    
    
    
}

@media screen and (max-width:600px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }
    
    .datosE h1{
        font-size: 2rem;
    }

    .first-des-reverse{
        
        margin-top: 2.5rem;
        margin-bottom: 10rem;
        
    }
    .des-textE{
        width: 75%;
        
    }
    .des-textE p{
        width: 80%;
        font-size: 1.2rem;
    }
}

@media screen and (max-width:500px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }

    .first-des-reverse{
        
        margin-top: 2.5rem;
        margin-bottom: 15rem;
        
    }
    .des-textE{
        width: 75%;
        
    }
    .des-textE p{
        width: 80%;
        font-size: 1.2rem;
    }
}




@media screen and (max-width:400px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }
    
    .datosE h1{
        font-size: 2rem;
        margin-left: -2rem;
    }

    .first-des-reverse{
        
        margin-top: 2.5rem;
        margin-bottom: 20rem;
        
    }
    .des-textE{
        width: 75%;
        
    }
    .des-textE p{
        width: 90%;
        font-size: 1.2rem;
    }
}


@media screen and (max-width:300px) {
    .datosE{
        margin: 4rem 2rem;
    }
    .DatosE{
        
        flex-direction: column;
    }
   
    .datosE h1{
        font-size: 1.8rem;
        margin-left: -3rem;
        margin-right: -2rem;
    }

    .first-des-reverse{
        
        margin-top: 2.5rem;
        margin-bottom: 20rem;
        
    }
    .des-textE{
        width: 95%;
        
    }
    .des-textE p{
        width: 80%;
        font-size: 1.2rem;
    }

}

