.datosL {
    margin: 4rem 6rem;
    text-align: center;
}

.datosL h1{
    font-size: 3rem;
    color: #004AAD;
}

.DatosL{
    margin-top: 3rem;
    display: flex;
    
    justify-content: space-between;
}

.t-cardL{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.19);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.t-cardL h4{
    font-size: 3rem;
    color: #004AAD;
}

.t_image{
    height: 500px;
    overflow: hidden;
    border-radius: 7px;
}
.t_image img{
    width: 100%;
    height: 100%;
}
.t-cardL h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
}


@media screen and (max-width:1500px) {
    .datosL{
        margin: 4rem 2rem;
        text-align: center;
    }
    .DatosL{
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
    }
    .t-cardL{
        width: 32%;
        text-align: start;
        box-shadow: 0 5px 25px 2px rgba(0,0,0,0.19);
        border-radius: 7px;
        cursor: pointer;
        padding: 1rem .8rem;
    }
}

@media screen and (max-width:1000px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
    .t-cardL{
        width: 100%;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width:700px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
   
}

@media screen and (max-width:600px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
   
    .datosL h1{
        font-size: 2rem;
    }
}

@media screen and (max-width:500px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
    
}




@media screen and (max-width:400px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
    .t-cardL{
        
        width: 15rem;
        margin-left: -3rem;
        margin-bottom: 1.5rem;
        
        
    }
    .datosL h1{
        font-size: 2rem;
        margin-left: -2rem;
        
    }
}


@media screen and (max-width:300px) {
    .datosL{
        margin: 4rem 2rem;
    }
    .DatosL{
        
        flex-direction: column;
    }
    .t-cardL{
        
        width: 13rem;
        margin-left: -3rem;
        margin-bottom: 1.5rem;
        
    }
    .datosL h1{
        font-size: 1.8rem;
        margin-left: -2rem;
        
    }

}
