.HeroImg{
    width: 100%;
    height: 40rem;
    position: relative;
}



img{
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

@media screen and (max-width: 1000px){
    .HeroImg{
        width: 100%;
        height: 30rem;
        position: relative;
    }
}

@media screen and (max-width: 500px){
    .HeroImg{
        width: 100%;
        height: 20rem;
        position: relative;
    }
}





