.Restaurantes{
    font-family: 'Poppins', sans-serif;
}

h1{
    margin-top: 5rem;
    position: relative;
    font-size: 3rem;
    color: #004AAD;
    text-align: center;
    
}

h2{
    position: relative;
    font-size: 2rem;
    
    text-align: center;
    
}

.first-desV{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-left: 4rem;
}

.first-desF{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-left: 4rem;
    margin-right: 2rem;
    margin-bottom: 4rem;
}

.first-desF h1{
    width: 60%;
}

.video{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1250px) {

    .first-desV{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 5rem;
        margin-top: 2.5rem;
    }

    .first-desF{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 5rem;
        margin-top: 2.5rem;
        margin-bottom: 3rem;
    }
    

    
}

@media screen and (max-width: 700px) {

    .first-desV{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        margin-left: -5rem;
        
    }

    .first-desF{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0;
        margin-top: 2.5rem;
        
    }

    .first-desF h1{
        font-size: 1.5rem;
        width: 80%;
    }
    

    
}

@media screen and (max-width: 400px) {

    

    .first-desF  h1{
        font-size: 1.3rem;
        
    }
    

    
}


