.BotonF button{
    text-decoration: none;
    background-color: #e43b25;
    width: 300px;
    height: 300px;
    
    cursor: pointer;
    margin-top: 2rem;
    margin-left: 1rem;
    padding: 1rem 2.5rem;
    border-radius: 6px;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
}

@media screen and (max-width: 500px) {
    .BotonF button{
        text-decoration: none;
        background-color: #e43b25;
        width: 250px;
        height: 250px;
        font-size: 2rem;
        margin-left: 1rem;
        
        
    }
}
