.Empleo{
    font-family: 'Poppins', sans-serif;
}
.datosEmpleo {
    margin: 4rem 6rem;
    text-align: center;
}

.datosEmpleo h1{
    margin-bottom: 3rem;
}


.first-desEm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 5rem;
}

.first-desEm h1{
    display: flex;
    color: #000;
    margin-left: 10rem;

}

@media screen and (max-width: 1250px) {
    
    
    .first-desEm{
        display: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 2.5rem;
    }

    .first-desEm h1{
        display: flex;
        padding-top: 1.5rem;
        margin-left: 0;
        font-size: 2rem;
    }

}


@media screen and (max-width: 400px) {
    

    .datosEmpleo h1{
        
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

}