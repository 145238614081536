.Calendar{
    text-align: center;
}


@media screen and (max-width: 300px){
    .Calendar{
        width: 280px;
        
    
    }
}