button{
    text-decoration: none;
    background-color: #004AAD;
    cursor: pointer;
    margin-top: 2rem;
    margin-left: 1rem;
    padding: 1rem 2.5rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
}
