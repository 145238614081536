.datosR {
    margin: 4rem 6rem ;
    text-align: center;
}

.datosR h1{
    font-size: 3rem;
    color: #2538e4;
}

.DatosR{
    margin-top: 3rem;
    display: flex;
    
    justify-content: space-between;
}

.t-cardR{
    width: 32%;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.19);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.t-cardR h4{
    font-size: 2.2rem;
    color: #2538e4;
}
.t-cardR h5{
    font-size: 1.5rem;
    color: #000000;
    text-align: center;
}

img{
    height: 300px;
}




@media screen and (max-width:1250px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
    .t-cardR{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    img{
        height: 100%;
    }
    
    
}

@media screen and (max-width:1000px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
    }
    
}

@media screen and (max-width:700px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
   
}

@media screen and (max-width:600px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
   
    .datosR h1{
        font-size: 2rem;
    }
}

@media screen and (max-width:500px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
    
}




@media screen and (max-width:400px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
    
    .datosR h1{
        font-size: 2rem;
        margin-left: -2rem;
        
    }

    .t-cardR h4{
        font-size: 1.2rem;
        color: #000000;
    }
}


@media screen and (max-width:300px) {
    .datosR{
        margin: 4rem 2rem;
    }
    .DatosR{
        
        flex-direction: column;
    }
    
    .datosR h1{
        font-size: 1.8rem;
        margin-left: -2rem;
        
    }

}
